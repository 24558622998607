import { ValidationControllerFactory, ValidationController, ValidationRules } from 'aurelia-validation';
import { autoinject } from 'aurelia-framework';
import { PageBase } from '../contents/Base/page-base';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../../service/tenant/tenant-context';
import { TenantService } from '../../service/tenant/tenant-service';
import { UILayoutService } from "../../service/ui/ui-layout-service";
import { EventAggregator } from 'aurelia-event-aggregator';
import { UiNavigationService } from "../../service/ui/ui-navigation-service";
import { ParticipationService } from '../../service/participation/participation-service';
import { BootstrapErrorRenderer } from '../contents/CustomElements/BootstrapErrorRenderer';
import { ProgramInfo, TenantViewModel } from '../../view-models/tenant-view-models';

@autoinject()
export class Index extends PageBase {

    public tenant: string = '';
    public tenantInfo: ProgramInfo;
    public kvnr: string = '';
    public insuranceNumber: string = "";
    public errorOccured: boolean = false;
    public hasOnlineAccount: boolean = false;
    public divClassExampleKvnrStyle: string = ""
    private validationController: ValidationController;
    private insuranceNumberList: string[];

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, private eventAggregator: EventAggregator, 
        public nav: UiNavigationService, private validationControllerFactory: ValidationControllerFactory, private participationService: ParticipationService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapErrorRenderer());
        this.setTenantInfo();
        this.tenant = this.tenantProvider.getTenant().key;
        this.divClassExampleKvnrStyle="background-image:url('" + this.rootPath + "Assets/ExampleKVNR.jpg')"

        ValidationRules.customRule('validateInsuranceNumberByTenantList', (value: string) => {
           
            var list = this.insuranceNumberList;
            for (var number of list) {
                if (number == value)
                    return true;
            }
            return false;
        }, 'Bitte geben Sie eine gültige Kassennummer an.');

        ValidationRules.ensure("kvnr")
            .required().withMessage('Bitte geben Sie Ihre Versichertennummer an.')
            .then().minLength(9).withMessage("Mindestens 9 Zeichen.")
            .then().maxLength(10).withMessage("Nicht mehr als 10 Zeichen.")
            .then().matches(new RegExp(/^[a-zA-Z]?\d{9}$/)).withMessage("Bitte überprüfen Sie Ihre Eingabe.")
            .on(this);
        ValidationRules.ensure("insuranceNumber")
            .required().withMessage('Bitte geben Sie Ihre Kassennummer an. ')
            .then().satisfiesRule("validateInsuranceNumberByTenantList")
            .on(this);

    }

    async activate(params: any, route, navigationInstruction) {
        var tenants = await this.tenantService.getTenantsByProgramType();
        this.insuranceNumberList = new Array();
        for(var tenant of tenants){
           var insuranceList = tenant.ui.insuranceNumber.split(';');
           this.insuranceNumberList= this.insuranceNumberList.concat(insuranceList);
        }
    }

    setTenantInfo() {
        var activeTenant = this.tenantProvider.getTenant();
        this.tenantInfo = activeTenant.ui.programs[0];
    }

    async submitData() {
        if ((await this.validationController.validate()).valid) {

            await this.checkAndChangeTenant();

            try {
                var result = await this.participationService.getStatus(this.tenantProvider.getTenant().id, this.getLoyalityProgram().id, this.kvnr);
                if (!result.alreadyRegistered) {
                    this.tenant == "nordwest"?  this.nav.navigate('teilnahme-anfordern', { kvnr: this.kvnr.toUpperCase() }) : this.nav.navigate('mitteilung');
                } else {
                    this.hasOnlineAccount = result.onlineAccessExists;
                    if (!result.onlineAccessExists) {
                        this.nav.navigate('online-zugang-anfordern', { kvnr: this.kvnr.toUpperCase() });
                    }
                }
            } catch (error) {
                this.errorOccured = true;
            }
        }
    }

    async checkAndChangeTenant() {
        var tenants = await this.tenantService.getTenantsByProgramType();
        var newTenant = tenants.find(t => t.ui.insuranceNumber.indexOf(this.insuranceNumber)>=0);
        var currTenant = this.tenantProvider.getTenant();
        if (newTenant && newTenant.insuranceId != currTenant.insuranceId) {
            this.tenantProvider.setTenant(newTenant, true);            
        }
    }

    async reInitilizeData() {
        this.errorOccured = false;
        this.hasOnlineAccount = false;
    }
}